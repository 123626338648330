var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"col-sm-6 offset-sm-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('BackLink'),_c('h2',{staticClass:"text-h5 grey--text text-capitalize text--darken-2",staticStyle:{"lineheight":"1.7"}},[_vm._v(" List "+_vm._s(_vm.$store.state.settings.defaultData)+" ")])],1),_c('modal-trash-box',{attrs:{"is-active":_vm.isModalActive,"trash-subject":_vm.trashObjectName},on:{"confirm":_vm.trashConfirm,"cancel":_vm.trashCancel}}),(!_vm.search.q || _vm.filter)?_c('v-tabs',{staticClass:"mb-2 py-0",attrs:{"active-class":"primary white--text","grow":"","height":"25px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"rgb(255 255 255 / 0%)"}}),_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.text,staticClass:"rounded-lg text-body-2",attrs:{"ripple":false}},[_vm._v(" "+_vm._s(item.text)+" "+_vm._s(item.count ? `(${item.count})` : null)+" ")])})],2):_vm._e(),_c('v-text-field',{staticClass:"px-0 mb-1",attrs:{"dense":"","solo":"","flat":"","hide-details":"","placeholder":`CARI ${
            _vm.$store.state.settings.defaultData &&
            _vm.$store.state.settings.defaultData.toUpperCase()
          }`,"background-color":"#efefef","color":"#00cccc"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.paginated = false;
            _vm.onSearch();}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{staticClass:"ml-n2 ma-1",class:_vm.filter ? 'primary' : 'grey',attrs:{"icon":"","tile":"","small":""},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-tune-variant")])],1)]},proxy:true},(
              Object.keys(_vm.search).length !== 0 &&
              _vm.search.constructor === Object
            )?{key:"append",fn:function(){return [_c('v-icon',{attrs:{"title":"Hapus Filter","color":"primary"},on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("mdi-close-circle")])]},proxy:true}:null],null,true),model:{value:(_vm.search.q),callback:function ($$v) {_vm.$set(_vm.search, "q", $$v)},expression:"search.q"}}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab-item',{key:item.text},[_c('FilterButton',{on:{"doFilter":(v) => _vm.doFilter(v),"clearFilter":_vm.clearSearch,"exportData":(v) => _vm.exportData(v)}}),(_vm.isLoading)?_vm._l((3),function(x){return _c('v-skeleton-loader',{key:x,staticClass:"mx-auto mb-1",attrs:{"height":"60px","tile":"","type":"list-item-two-line"}})}):[(!_vm.data.length)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[[_c('p',[_vm._v("DATA BELUM ADA")])]],2)]):_vm._l((_vm.data),function(item){return _c('v-card',{key:item.id,staticClass:"rounded-r-lg text-uppercase white mb-2",attrs:{"elevation":"1"}},[_c('v-list-group',{staticClass:"rounded-lg",attrs:{"color":"black","no-action":"","append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-container',{staticClass:"pa-2 grey--text text--darken-3"},[_c('div',{staticClass:"d-flex"},[(
                              _vm.$store.state.settings.defaultData === 'pasien'
                            )?_c('v-chip',{staticClass:"mr-1 px-1 text-normal grey darken-1 white--text",attrs:{"label":"","x-small":""}},[_vm._v(" NO RM ")]):_vm._e(),_c('div',{staticClass:"nexa-black mt-1",staticStyle:{"font-size":"1em","lineheight":"1.5"}},[_vm._v(" "+_vm._s(item.no_rm || item.content.id_staff || item.content.kode)+" ")]),_c('v-btn',{staticClass:"ml-auto nexa-black mt-1 text-capitalize",attrs:{"outlined":"","x-small":"","color":"grey darken-1"}},[_vm._v(" "+_vm._s(_vm.formatDate( item.updated_at, "DD MMM YYYY - H:mm" ))+" ")])],1),_c('div',{staticClass:"text-capitalize mb-1",staticStyle:{"line-height":"1","font-size":"1em"}},[_vm._v(" "+_vm._s(item.nama)+" ")]),_c('div',{staticClass:"text-caption mb-1 grey--text",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(item.content.poli || item.content.ruangan || item.content.unit || item.content.klinik || "-")+" ")]),(
                            item.content.keterangan || item.content.status
                          )?_c('v-chip',{staticClass:"ml-auto",attrs:{"x-small":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(item.content.keterangan || item.content.status)+" ")]):_vm._e()],1)]},proxy:true}],null,true)},[_c('v-card-text',_vm._l((_vm.form),function(itemx,i){return _c('v-row',{key:i,staticClass:"align-center text-uppercase",attrs:{"no-gutters":""}},[(itemx.type !== 'upload')?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"col-12 my-0 pa-0"})],1),_c('v-col',{staticClass:"pt-1 pb-0",staticStyle:{"font-size":"8pt"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(itemx.label)+" ")]),_c('v-col',{staticClass:"pt-1 pb-0 text-right",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s((itemx.type === "date" ? _vm.formatDate( item.content[itemx.model], "D MMM YYYY" ) : item[itemx.model] || item.content[itemx.model]) || "-")+" ")])]:_vm._e()],2)}),1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[[_c('v-btn',{staticClass:"grey--text col",attrs:{"depressed":"","small":"","outlined":""},domProps:{"textContent":_vm._s('Hapus')},on:{"click":function($event){$event.preventDefault();return _vm.trashModal(item)}}}),(_vm.tab < 3)?_c('v-btn',{staticClass:"white--text col",attrs:{"depressed":"","small":"","light":"","color":"#00cccc"},domProps:{"textContent":_vm._s(_vm.prosesButton.text)},on:{"click":function($event){return _vm.changeProses(item._id, _vm.prosesButton.value)}}}):_vm._e(),_c('v-btn',{staticClass:"white--text col",attrs:{"depressed":"","small":"","light":"","color":"#00cccc","to":{
                            name: 'form.edit',
                            params: { id: item._id },
                          }},domProps:{"textContent":_vm._s('Edit')}})]],2)],1)],1)})],[(_vm.loadMore)?_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',{staticClass:"text-center"},[(_vm.paginated)?_c('v-btn',{staticClass:"my-2",attrs:{"depressed":"","small":"","light":"","outlined":"","color":"#00cccc"},domProps:{"textContent":_vm._s('Load More')},on:{"click":function($event){return _vm.infiniteScrolling()}}}):_vm._e()],1)]],2)}),1)],1)],1)],1),_c('a',{staticStyle:{"position":"fixed","top":"60%","z-index":"99","right":"0"},attrs:{"href":"#/form"}},[_c('v-img',{attrs:{"src":require("@/assets/images/plus-side.png"),"max-width":"20"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }