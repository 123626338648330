<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col class="col-sm-6 offset-sm-3">
          <div class="d-flex justify-space-between">
            <BackLink />
            <h2
              class="text-h5 grey--text text-capitalize text--darken-2"
              style="lineheight: 1.7"
            >
              List
              {{ $store.state.settings.defaultData }}
            </h2>
          </div>

          <modal-trash-box
            :is-active="isModalActive"
            :trash-subject="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <v-tabs
            v-if="!search.q || filter"
            active-class="primary white--text"
            v-model="tab"
            grow
            height="25px"
            class="mb-2 py-0"
          >
            <v-tabs-slider color="rgb(255 255 255 / 0%)"></v-tabs-slider>
            <v-tab
              v-for="item in tabs"
              :ripple="false"
              :key="item.text"
              class="rounded-lg text-body-2"
            >
              {{ item.text }} {{ item.count ? `(${item.count})` : null }}
            </v-tab>
          </v-tabs>
          <v-text-field
            v-model="search.q"
            @keydown.enter="
              paginated = false;
              onSearch();
            "
            dense
            solo
            flat
            hide-details
            :placeholder="`CARI ${
              $store.state.settings.defaultData &&
              $store.state.settings.defaultData.toUpperCase()
            }`"
            background-color="#efefef"
            color="#00cccc"
            class="px-0 mb-1"
          >
            <template v-slot:prepend-inner>
              <v-btn
                class="ml-n2 ma-1"
                :class="filter ? 'primary' : 'grey'"
                icon
                tile
                small
                @click="filter = !filter"
              >
                <v-icon small color="white">mdi-tune-variant</v-icon>
              </v-btn>
            </template>
            <template
              v-slot:append
              v-if="
                Object.keys(search).length !== 0 &&
                search.constructor === Object
              "
            >
              <v-icon
                title="Hapus Filter"
                color="primary"
                @click="clearSearch()"
                >mdi-close-circle</v-icon
              >
            </template>
          </v-text-field>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabs" :key="item.text">
              <FilterButton
                @doFilter="(v) => doFilter(v)"
                @clearFilter="clearSearch"
                @exportData="(v) => exportData(v)"
              />
              <template v-if="isLoading">
                <v-skeleton-loader
                  v-for="x in 3"
                  :key="x"
                  class="mx-auto mb-1"
                  height="60px"
                  tile
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <section class="section" v-if="!data.length">
                  <div class="content has-text-grey has-text-centered">
                    <template>
                      <p>DATA BELUM ADA</p>
                    </template>
                  </div>
                </section>
                <template v-else>
                  <v-card
                    elevation="1"
                    class="rounded-r-lg text-uppercase white mb-2"
                    v-for="item in data"
                    :key="item.id"
                  >
                    <v-list-group
                      color="black"
                      class="rounded-lg"
                      no-action
                      append-icon=""
                    >
                      <template v-slot:activator>
                        <v-container class="pa-2 grey--text text--darken-3">
                          <div class="d-flex">
                            <v-chip
                              v-if="
                                $store.state.settings.defaultData === 'pasien'
                              "
                              label
                              x-small
                              class="mr-1 px-1 text-normal grey darken-1 white--text"
                            >
                              NO RM
                            </v-chip>
                            <div
                              class="nexa-black mt-1"
                              style="font-size: 1em; lineheight: 1.5"
                            >
                              {{
                                item.no_rm ||
                                item.content.id_staff ||
                                item.content.kode
                              }}
                            </div>
                            <v-btn
                              outlined
                              x-small
                              color="grey darken-1"
                              class="ml-auto nexa-black mt-1 text-capitalize"
                            >
                              {{
                                formatDate(
                                  item.updated_at,
                                  "DD MMM YYYY - H:mm"
                                )
                              }}
                            </v-btn>
                          </div>
                          <div
                            class="text-capitalize mb-1"
                            style="line-height: 1; font-size: 1em"
                          >
                            {{ item.nama }}
                          </div>
                          <div
                            class="text-caption mb-1 grey--text"
                            style="line-height: 1"
                          >
                            {{
                              item.content.poli ||
                              item.content.ruangan ||
                              item.content.unit ||
                              item.content.klinik ||
                              "-"
                            }}
                          </div>
                          <v-chip
                            v-if="
                              item.content.keterangan || item.content.status
                            "
                            x-small
                            outlined
                            color="primary"
                            class="ml-auto"
                          >
                            {{ item.content.keterangan || item.content.status }}
                          </v-chip>
                        </v-container>
                      </template>
                      <v-card-text>
                        <v-row
                          v-for="(itemx, i) in form"
                          :key="i"
                          no-gutters
                          class="align-center text-uppercase"
                        >
                          <template v-if="itemx.type !== 'upload'">
                            <v-col cols="12" class="pa-0">
                              <v-divider class="col-12 my-0 pa-0"></v-divider>
                            </v-col>
                            <v-col
                              cols="4"
                              class="pt-1 pb-0"
                              style="font-size: 8pt"
                            >
                              {{ itemx.label }}
                            </v-col>
                            <v-col cols="8" class="pt-1 pb-0 text-right">
                              {{
                                (itemx.type === "date"
                                  ? formatDate(
                                      item.content[itemx.model],
                                      "D MMM YYYY"
                                    )
                                  : item[itemx.model] ||
                                    item.content[itemx.model]) || "-"
                              }}
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>

                      <v-card-actions class="d-flex justify-space-between">
                        <template>
                          <v-btn
                            depressed
                            small
                            outlined
                            class="grey--text col"
                            @click.prevent="trashModal(item)"
                            v-text="'Hapus'"
                          />
                          <v-btn
                            v-if="tab < 3"
                            depressed
                            small
                            light
                            color="#00cccc"
                            class="white--text col"
                            @click="changeProses(item._id, prosesButton.value)"
                            v-text="prosesButton.text"
                          />
                          <v-btn
                            depressed
                            small
                            light
                            color="#00cccc"
                            class="white--text col"
                            :to="{
                              name: 'form.edit',
                              params: { id: item._id },
                            }"
                            v-text="'Edit'"
                          />
                        </template>
                      </v-card-actions>
                    </v-list-group>
                  </v-card>
                </template>
              </template>
              <!--Add here the vuetify directive -->
              <template>
                <div style="width: 100%" class="text-center" v-if="loadMore">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-else class="text-center">
                  <v-btn
                    v-if="paginated"
                    depressed
                    small
                    light
                    outlined
                    color="#00cccc"
                    class="my-2"
                    @click="infiniteScrolling()"
                    v-text="'Load More'"
                  />
                </div>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <a href="#/form" style="position: fixed; top: 60%; z-index: 99; right: 0">
      <v-img src="@/assets/images/plus-side.png" max-width="20"></v-img>
    </a>

    <!-- <modal-import ref="modalImport" title="Import Data" /> -->
  </v-main>
</template>

<script>
import ModalTrashBox from "@/components/ModalTrashBox";

export default {
  name: "Index",
  components: {
    ModalTrashBox,
    // ModalImport: () => import("@/components/ModalImport"),
    FilterButton: () => import("@/components/Filter"),
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      data: [],
      search: {},
      isLoading: null,
      dataUrl: "/pasien",
      loadMore: false,
      total: 0,
      paginated: false,
      page: 1,
      perPage: 15,
      filter: false,
      filterData: null,
      dialog: false,
      tab: 0,
      tabs: [
        { text: "List", status: "antrian", count: 0 },
        { text: "Proses", status: "proses", count: 0 },
        { text: "Ready", status: "ready", count: 0 },
        { text: "Selesai", status: "selesai", count: 0 },
      ],
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return this.trashObject.nama;
      }

      return null;
    },
    form() {
      return this.$store.state.settings.form;
    },
    prosesButton() {
      let value;
      if (this.tab === 0) {
        value = { text: "Proses", value: "proses" };
      } else if (this.tab === 1) {
        value = { text: "Ready", value: "ready" };
      } else if (this.tab === 2) {
        value = { text: "Selesai", value: "selesai" };
      }
      return value;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    changeProses(id, status) {
      setTimeout(() => {
        this.axios
          .patch(`${this.dataUrl}/${id}`, { status: status })
          .then(() => {
            // console.log(r.data);
            this.$snackbar({
              text: "Status berhasil diupdate",
            });
            this.data = this.data.filter((item) => item._id !== id);
            this.tabs[this.tab].count -= 1;
            if (this.tabs[this.tab + 1].count > 0) {
              this.tabs[this.tab + 1].count += 1;
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }, 500);
    },
    getData(page) {
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.data = page ? this.data : [];
      this.isLoading = page ? false : true;
      if (this.dataUrl) {
        let tab = this.tabs[this.tab].status;
        let url = `${this.dataUrl}?status=${tab}&$skip=${skip}&$limit=15&$sort[updated_at]=-1`;
        setTimeout(() => {
          this.axios
            .get(url)
            .then((r) => {
              r.data.data.forEach((item) => {
                this.data.push(item);
              });
              this.paginated = r.data.data.length >= 15 ? true : false;
              this.page = pageLoad;
              this.tabs[this.tab].count = r.data.total;
              console.log(this.paginated);
            })
            .catch((err) => {
              this.data = [];
              console.log(err.message);
            })
            .finally(() => {
              this.isLoading = false;
              this.loadMore = false;
            });
        }, 500);
      }
    },
    infiniteScrolling() {
      this.loadMore = true;
      console.log(this.paginated, this.page);
      if (!this.paginated) {
        return;
      }
      setTimeout(() => {
        if (this.search.q) {
          this.onSearch(this.page + 1);
        } else if (this.filter) {
          this.doFilter(null, this.page + 1);
        } else {
          this.getData(this.page + 1);
        }
      }, 500);
    },
    onSearch(page) {
      this.data = page ? this.data : [];
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.isLoading = page ? false : true;
      //console.log(this.search)
      setTimeout(() => {
        // Lazily load input items
        let filter = [
          {
            trigger: "no_rm",
            value: this.search.q,
          },
          {
            trigger: "nama",
            value: this.search.q,
          },
        ];
        this.axios
          .post(`${window.rawUrl}/search`, {
            filter: filter,
            skip: skip,
            limit: 15,
            tanggal: 0,
          })
          .then((r) => {
            r.data.forEach((item) => {
              this.data.push(item);
            });
            this.paginated = r.data.length >= 15 ? true : false;
            this.page = pageLoad;
            console.log(this.paginated);
          })
          .catch((err) => {
            this.data = [];
            console.log(err.message);
          })
          .finally(() => {
            this.isLoading = false;
            this.loadMore = false;
          });
      }, 500);
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      this.axios
        .delete(`${this.dataUrl}/${this.trashObject._id}`)
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    trashCancel() {
      this.isModalActive = false;
    },
    clearSearch() {
      this.loadMore = false;
      this.paginated = false;
      this.filter = false;
      this.page = 1;
      this.data = [];
      this.search = {};
      this.getData();
    },
    doFilter(v, page) {
      this.data = page ? this.data : [];
      let pageLoad = parseInt(page) || 1;
      let skip = parseInt(page) > 1 ? (parseInt(page) - 1) * 15 : 0;
      this.isLoading = page ? false : true;

      this.filter = true;
      if (v) {
        let filter = v.filter.map((item) => {
          return {
            trigger: `content.${v.trigger}`,
            value: item,
          };
        });
        // console.log(filter);
        this.filterData = {
          filter: filter,
          tanggal: parseInt(v.tanggal),
        };
      }

      if (this.dataUrl) {
        setTimeout(() => {
          if (this.filterData.tanggal !== 0 || this.filterData.filter.length) {
            this.axios
              .post(
                `${window.rawUrl}/search`,
                Object.assign(this.filterData, {
                  skip: skip,
                  limit: 15,
                })
              )
              .then((r) => {
                r.data.forEach((item) => {
                  this.data.push(item);
                });
                this.paginated = r.data.length >= 15 ? true : false;
                this.page = pageLoad;
                console.log(this.paginated);
              })
              .catch((err) => {
                this.data = [];
                console.log(err.message);
              })
              .finally(() => {
                this.isLoading = false;
                this.loadMore = false;
              });
          } else {
            this.clearSearch();
          }
        }, 500);
      }
    },
    exportData(custom) {
      let url = `${window.rawUrl}/export`;
      let data = {
        filter: this.filterData.filter,
        tanggal: this.filterData.tanggal,
        form: this.form,
      };
      if (custom) {
        url = `${window.rawUrl}/custom-export`;
        data = {
          filter: this.filterData.filter,
          tanggal: this.filterData.tanggal,
          form: this.form,
          waktu: custom,
          data: {
            menu: this.$store.state.settings.menu || [],
            diet: this.$store.state.settings.diet || [],
          },
        };
      }
      setTimeout(() => {
        this.axios
          .post(url, data)
          .then((r) => {
            console.log(r.data);
            window.location.href =
              window.rawUrl + "/assets/uploads/files/" + r.data.path;
          })
          .catch((err) => {
            console.log(err.message);
          });
      }, 500);
      this.dialog = false;
    },
    importData() {
      this.$refs.modalImport
        .open()
        .then((res) => {
          // this.search = res
          // this.onSearch()
          console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  watch: {
    tab() {
      this.paginated = false;
      this.getData();
    },
    // search(val) {
    //   if (!val) {
    //     this.filterPoli = this.data;
    //     return;
    //   }
    //   val && this.onSearch(val);
    // },
  },
};
</script>
